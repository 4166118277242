import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import Table from "lib/@cms/components/cms/Table";
import Callout from "lib/@cms/components/cms/Callout";
import Divider from "lib/@cms/components/primitive/Divider";

function RatesPage() {
  return (
    <Page pathname="rates">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <Divider className="tw-my-7" />
            <div className="tw-container">
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>

              {data.Tables?.map((table, index) => {
                return (
                  <React.Fragment key={`TableContent-${index}`}>
                    <Table
                      title={table.title}
                      description={table.description}
                      HeadTitles={table.HeadTitles}
                      TableItems={table.TableItems}
                    />
                    <Divider className="tw-my-7 sm:tw-my-10" />
                  </React.Fragment>
                );
              })}

              {data.TextsLists?.map((item, index) => {
                return (
                  <React.Fragment key={`CalloutText-${index}`}>
                    <Callout variant={Callout.variant.TEXT} title={item.title} list={item.list} />
                    <Divider className="tw-my-7 sm:tw-my-32" />
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default RatesPage;
